import React, { createContext, useState } from "react";
//import GlobalReducer from "./GlobalReducer";

const initialState = { qurbanPrice: 150 };
export const GlobalContext = createContext(initialState);
export const GlobalContextProvider = ({ children }) => {
  // const [state, dispatch] = useReducer(GlobalReducer, initialState);
  const [totalCost, setTotalCost] = useState(0);
  const [checkout, setCheckout] = useState(0);
  const [donations, setDonations] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [coDrawerOpen, setCoDrawerOpen] = useState(false);
  const [coTooltip, setCoTooltip] = useState(false);
  const [donatModal, setDonatModal] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <GlobalContext.Provider
      value={{
        qurbanPrice: initialState.qurbanPrice,
        totalCost,
        setTotalCost,
        coTooltip,
        setCoTooltip,
        donatModal,
        setDonatModal,
        isCollapsed,
        setIsCollapsed,
        coDrawerOpen,
        selectedProject,
        setSelectedProject,
        setCoDrawerOpen,
        checkout,
        setCheckout,
        donations,
        setDonations,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
