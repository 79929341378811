import { notification } from "antd";
import React from "react";
import { Route, Redirect } from "react-router-dom";

const AdminRoute = ({ component: Component, ...rest }) => {
  const myRole = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).role
    : "Guest";
  if (myRole !== "Admin") {
    notification.destroy();
    notification.warn({
      message: "Unauthorized transaction!",
      description: "Please login again",
    });
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        myRole === "Admin" ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/login/loginpage",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export { AdminRoute };
