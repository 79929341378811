import { PageHeader, Image, Descriptions, Tag, Row, Col, Divider } from "antd";
import moment from "moment";
import React from "react";
import ReactPlayer from "react-player";
import { useHistory, useLocation } from "react-router-dom";

const ProjectAdminDetails = () => {
  const location = useLocation();
  // @ts-ignore
  const { veri } = location.state || { veri: null };
  const router = useHistory();
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }
  return (
    <div style={{ maxWidth: "800px", padding: "0 10px" }}>
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title={veri.name}
        extra={[
          <Tag key="scope" color="#d46b08">
            {veri.scope.name}
          </Tag>,
        ]}
      />

      <Descriptions
        size="small"
        bordered
        column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Description" span={2}>
          {veri.description}
        </Descriptions.Item>
        <Descriptions.Item label="Pay.Interval" span={2}>
          {veri.intervals.map((p) => (
            <Tag key={p.id} color="blue">
              {p.name}
            </Tag>
          ))}
        </Descriptions.Item>

        <Descriptions.Item label="Start Date">
          {moment(veri.startDate).format("DD-MM-YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="End Date">
          {moment(veri.endDate).format("DD-MM-YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Is Active ?">
          {veri.isActive ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label="Is Favorite ?">
          {veri.isFavorite ? "Yes" : "No"}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Image
        preview={false}
        style={{
          maxWidth: "800px",
          border: "1px solid #ddd",
        }}
        src={process.env.REACT_APP_FILES_BASEURL + veri.images[0].url}
        alt=""
      />
      <br />
      <br />
      <div dangerouslySetInnerHTML={createMarkup(veri.details)} />
      <br />

      {veri.images.length > 1 && (
        <>
          <Divider orientation="left">Image Gallery</Divider>
          <Image.PreviewGroup>
            <Row align="middle" gutter={[10, 10]}>
              {veri.images.map((imaj) => (
                <Col>
                  <Image
                    className="gallery-image"
                    width={200}
                    src={process.env.REACT_APP_FILES_BASEURL + imaj.url}
                  />
                </Col>
              ))}
            </Row>
          </Image.PreviewGroup>
        </>
      )}

      {veri.videos.length > 0 && (
        <>
          <Divider orientation="left">Video Gallery</Divider>

          <Row align="middle" gutter={[10, 10]}>
            {veri.videos.map((video) => (
              <Col>
                <ReactPlayer url={video.url} controls={true} />
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
};

export default ProjectAdminDetails;
