import { DeleteOutlined } from "@ant-design/icons";
import {
  Space,
  Select,
  Card,
  Col,
  Row,
  Statistic,
  Table,
  Button,
  Popconfirm,
  Tooltip,
  Divider,
  PageHeader,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AdminService } from "../../services/AdminService";

const QtyDashboard = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [qtyProjects, setQtyProjects] = useState([]);
  const [activeProject, setActiveProject] = useState();
  const [veri, setVeri] = useState(null);
  const [tablo, setTablo] = useState([]);
  const [aktif, setAktif] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getProjectByType(2).then((data) => {
        setQtyProjects(data.data);
      });
    }
  }, [initialRender]);
  const projectSelected = (id) => {
    setActiveProject(id);
    AdminService.getProjectById(id).then((data) => {
      AdminService.getQtyData(data.data.key).then((data) => {
        setVeri(data.data);

        setTablo(data.data);
        setAktif(data.data[0].itemDonations);
        setActiveIndex(0);
      });
    });
  };
  const getItemDonations = (ind) => {
    setAktif(tablo[ind].itemDonations);
    setActiveIndex(ind);
  };
  const deleteItem = (id) => {
    AdminService.deleteQtyItemDonation(id).then((data) => {
      AdminService.getQtyData(activeProject).then((data) => {
        setVeri(data.data);

        setTablo(data.data);
      });
    });
  };
  const columns = [
    {
      title: "D.Id",
      dataIndex: "donationId",
      key: "donationId",
      render: (donationId) => (
        <Link to={"/admin/donations/" + donationId}>{donationId}</Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (createdOn) => moment(createdOn).format("DD-MM-YY dddd"),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total) => "$" + total,
    },
    {
      title: "Act.",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Tooltip title="Delete " placement="left">
          <Popconfirm
            title="Are you sure ? You cannot undo this action !"
            onConfirm={() => deleteItem(id)}
            okText="Yes! Delete."
            cancelText="Cancel"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title="Quantities"
      />
      <br />
      <Space>
        <span>Choose Quantity Project</span>
        <Select
          style={{ minWidth: "250px" }}
          placeholder="Please choose project"
          onChange={projectSelected}
        >
          {qtyProjects.map((q) => (
            <Select.Option key={q.key} value={q.project.id}>
              {q.project.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <br />
      <br />
      {veri && (
        <div>
          <div
            style={{
              backgroundColor: "#f5f5f5",
              padding: "15px",
              border: "1px solid #ddd",
            }}
          >
            <Row gutter={[16, 16]}>
              {veri.map((v, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    display: "inline-flex",
                    alignSelf: "stretch",
                  }}
                >
                  <Card
                    className="stat-card"
                    hoverable
                    onClick={() => getItemDonations(index)}
                    style={{
                      border: index === activeIndex ? "2px solid #D26D24" : "",
                    }}
                  >
                    <Statistic
                      title={
                        v.name +
                        " - $" +
                        v.price +
                        "x" +
                        v.itemDonations.reduce(function (prev, current) {
                          return prev + +current.qty;
                        }, 0) +
                        " qty"
                      }
                      value={v.itemDonations.reduce(function (prev, current) {
                        return prev + +current.total;
                      }, 0)}
                      prefix="$"
                      precision={2}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}
      {aktif.length > 0 && (
        <div
          style={{
            backgroundColor: "#f5f5f5",
            marginTop: "20px",
            padding: "15px",
            border: "1px solid #ddd",
          }}
        >
          <Divider orientation="left">Donation List</Divider>
          <Table
            dataSource={aktif}
            size="small"
            bordered
            columns={columns}
            scroll={{ x: 1000 }}
          />
        </div>
      )}
    </div>
  );
};

export default QtyDashboard;
