import {
  CheckOutlined,
  DeleteOutlined,
  FileTwoTone,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Modal,
  notification,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "../../services/AdminService";

const Approvals = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [approvals, setApprovals] = useState(null);
  const [cancelled, setCancelled] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getApprovals().then((data) => {
        setApprovals(data.data.waiting);
        setCancelled(data.data.cancelled);
      });
    }
  }, [initialRender]);
  const kolonlar = [
    {
      title: "Donation Date",
      dataIndex: "date",
      render: (date) => moment(date).format("DD-MM-YY ddd HH:mm"),
    },
    {
      title: "Donor Name",
      dataIndex: "fullname",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },

    {
      title: "Amount",
      dataIndex: "total",
      render: (total) => `$${total}`,
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      render: (receipt) =>
        receipt === null ? (
          <StopOutlined style={{ fontSize: "18px", color: "red" }} />
        ) : (
          <Button
            size="small"
            icon={
              <FileTwoTone
                twoToneColor="#E09F00"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_FILES_BASEURL + receipt,
                    "_blank"
                  )
                }
              />
            }
          />
        ),
    },
  ];
  const columns = [
    ...kolonlar,
    {
      title: "Actions",
      render: (id, item) => (
        <Space>
          <Tooltip title="Payment Details" placement="left">
            <Button
              type="primary"
              size="small"
              onClick={() => goDetails(item.donations)}
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip title="Approve" placement="left">
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => approveDonation(item.id)}
              okText="Yes! Approve."
              cancelText="Cancel"
            >
              <Button
                icon={<CheckOutlined />}
                style={{ backgroundColor: "limegreen", color: "white" }}
                size="small"
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Cancel Donation" placement="left">
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => deleteDonation(item.id)}
              okText="Yes! Cancel."
              cancelText="Close"
            >
              <Button
                size="small"
                type="primary"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const columns2 = [
    ...kolonlar,
    {
      title: "Actions",
      render: (id, item) => (
        <Space>
          <Tooltip title="Payment Details" placement="left">
            <Button
              type="primary"
              size="small"
              onClick={() => goDetails(item.donations)}
              icon={<SearchOutlined />}
            />
          </Tooltip>

          <Tooltip title="Cancel Donation" placement="left">
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => deleteDonation(item.id)}
              okText="Yes! Cancel."
              cancelText="Close"
            >
              <Button
                size="small"
                type="primary"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const deleteDonation = (id) => {
    AdminService.putCancelPayment(id).then((data) => {
      notification.success({
        message: "Success",
        description: "Payment cancelled successfully",
      });
      setInitialRender(true);
    });
  };
  const approveDonation = (id) => {
    AdminService.putApprovedPayment(id).then((data) => {
      notification.success({
        message: "Success",
        description: "Payment confirmed successfully",
      });
      setInitialRender(true);
    });
  };
  const goDetails = (dontaions) => {
    setModalData(dontaions);
    setModalOpen(true);
  };
  return (
    <div>
      <Modal
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={() => setModalOpen(false)}
        width="350px"
      >
        <Descriptions
          title="Payment Details"
          bordered
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          size="small"
        >
          {modalData.map((p) => (
            <Descriptions.Item key={p.key} label={p.projectName}>
              <span>${p.amount}</span>
            </Descriptions.Item>
          ))}
          <Descriptions.Item
            label={<b>Total Value</b>}
            style={{ border: "1px solid #ddd" }}
          >
            <b style={{ whiteSpace: "nowrap" }}>
              ${modalData.reduce((accum, item) => accum + item.amount, 0)}
            </b>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
      <p className="mt-3 text-gray-600 text-2xl font-extrabold">
        Donations Pending Approval
      </p>
      <Table
        scroll={{ x: 1000 }}
        dataSource={approvals}
        size="small"
        columns={columns}
        bordered
        loading={approvals === null}
      />
      <p className="mt-3 text-gray-600 text-2xl font-extrabold">
        Donations Cancelled
      </p>
      <Table
        scroll={{ x: 1000 }}
        dataSource={cancelled}
        size="small"
        columns={columns2}
        bordered
        loading={approvals === null}
      />
    </div>
  );
};

export default Approvals;
