import { DeleteOutlined } from "@ant-design/icons";
import { Button, notification, PageHeader, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../services/AdminService";

const Subscriptions = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [subs, setSubs] = useState([]);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getSubscriptions().then((data) => {
        setSubs(data.data);
      });
    }
  }, [initialRender]);
  const deleteSub = (id) => {
    AdminService.deleteSubscription(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Subscription deleted successfully",
      });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "sub",
      render: (item) => item.fullname,
    },
    {
      title: "Email",
      dataIndex: "sub",
      render: (item) => item.email,
    },
    {
      title: "Action",
      dataIndex: "sub",
      render: (sub) => (
        <Popconfirm
          title="Are you sure ?"
          onConfirm={() => deleteSub(sub.id)}
          okText="Yes! Delete."
          cancelText="Cancel"
        >
          <Button type="primary" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title="Subscribed users list"
      />
      <Table
        footer={() => subs.length + " users subscribed"}
        columns={columns}
        bordered
        dataSource={subs}
        size="small"
        style={{ maxWidth: "600px" }}
      />
    </div>
  );
};

export default Subscriptions;
