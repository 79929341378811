import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "nprogress/nprogress.css";
import { Router } from "react-router-dom";
import { GlobalContextProvider } from "./context/GlobalContext";
import { UserContextProvider } from "./context/UserContext";
import { axHistory } from "./resources/axRouter";
import ScrollToTop from "./resources/scrollToTop";

const Loader = () => (
  <div className="loader-bg">
    {/* <img
      src={require("./assets/images/progress.png")}
      alt="logo"
      width="250px"
    /> */}
    <br />
    <div className="sk-folding-cube">
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div>
  </div>
);
ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <Router history={axHistory}>
      <UserContextProvider>
        <GlobalContextProvider>
          <ScrollToTop />
          <App />
        </GlobalContextProvider>
      </UserContextProvider>
    </Router>
  </Suspense>,
  document.getElementById("root")
);
