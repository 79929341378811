import { DeleteOutlined } from "@ant-design/icons";
import { notification, Popconfirm, Button, PageHeader, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../services/AdminService";

const Contacts = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [contacts, setContacts] = useState([]);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getContacts().then((data) => {
        setContacts(data.data);
      });
    }
  }, [initialRender]);
  const deleteSub = (id) => {
    AdminService.deleteContact(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Contact deleted successfully",
      });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "c",
      render: (c) => moment(c.createdOn).format("DD MMM, ddd - HH:mm"),
    },
    {
      title: "Name",
      dataIndex: "c",
      render: (c) => c.fullname,
    },
    {
      title: "Email",
      dataIndex: "c",
      render: (c) => c.email,
    },
    {
      title: "Phone",
      dataIndex: "c",
      render: (c) => c.phone,
    },
    {
      title: "Subject",
      dataIndex: "c",
      render: (c) => c.subject,
    },
    {
      title: "Message",
      dataIndex: "c",
      render: (c) => c.message,
    },
    {
      title: "Action",
      dataIndex: "key",
      render: (key) => (
        <Popconfirm
          title="Are you sure ?"
          onConfirm={() => deleteSub(key)}
          okText="Yes! Delete."
          cancelText="Cancel"
        >
          <Button type="primary" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title="Contact list"
      />
      <Table
        scroll={{ x: 1100 }}
        footer={() => contacts.length + " contact"}
        columns={columns}
        bordered
        dataSource={contacts}
        size="small"
      />
    </div>
  );
};

export default Contacts;
