import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, notification, Select, Space, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AdminService } from "../../services/AdminService";

const CheckPayments = () => {
  const [donations, setDonations] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [ile, setIle] = useState("email");
  const emailChange = (e) => {
    setEmailInput(e.target.value);
  };
  const getDonations = () => {
    AdminService.getUserDonations({ value: emailInput, ile }).then((data) => {
      setDonations(data.data);
      if (data.data.length === 0) {
        notification.info({ message: "Donation not found." });
      }
    });
  };

  const columns = [
    {
      title: "D.Id",
      dataIndex: "key",
      render: (key) => <Link to={"/admin/donations/" + key}>{key}</Link>,
    },
    {
      title: "Project",
      dataIndex: "projectName",
    },
    {
      title: "D.Date",
      dataIndex: "veri",
      render: (veri) => moment(veri.createdOn).format("DD-MM-YY ddd"),
    },
    {
      title: "Total",
      dataIndex: "veri",
      render: (veri) => (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {"$" + veri.total.toFixed(2)}
        </div>
      ),
    },
  ];
  return (
    <div style={{ padding: "20px" }}>
      <h1 style={{ fontSize: "18px" }}>Check donations of donor</h1>
      <p style={{ lineHeight: 0, marginTop: "25px" }}>Search With...</p>
      <Space>
        <Select
          value={ile}
          onChange={(a) => setIle(a)}
          size="large"
          style={{ minWidth: "200px" }}
        >
          <Select.Option key="email" value="email">
            E-Mail Address
          </Select.Option>
          <Select.Option key="fullname" value="fullname">
            Fullname
          </Select.Option>
          <Select.Option key="phone" value="phone">
            Phone Number
          </Select.Option>
          <Select.Option key="suburb" value="suburb">
            Suburb
          </Select.Option>
          <Select.Option key="postcode" value="postcode">
            Postcode
          </Select.Option>
          <Select.Option key="state" value="state">
            State
          </Select.Option>
        </Select>
        <Input
          placeholder={"Type " + ile}
          size="large"
          onChange={emailChange}
        />
        <Button
          size="large"
          type="primary"
          icon={<SearchOutlined />}
          onClick={getDonations}
        >
          Search
        </Button>
      </Space>
      <br />
      <br />
      {donations.length > 0 && (
        <>
          <h1 style={{ color: "tomato" }}>
            {donations.length} donations found. Total donation price : $
            {donations[0].amount.toFixed(2)}
          </h1>
          <Table
            columns={columns}
            dataSource={donations}
            bordered
            scroll={{ x: 800 }}
          />
        </>
      )}
    </div>
  );
};

export default CheckPayments;
