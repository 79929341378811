import React, { useContext, useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { UserContext } from "../../context/UserContext";
import LoginForm from "./LoginForm";

const Loginpage = () => {
  const [initialRender, setInitialRender] = useState(true);
  const { setUserValues } = useContext(UserContext);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      setUserValues({});
    }
  }, [initialRender, setUserValues]);
  return (
    <div className="login-page">
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_reCaptchaKey}
      >
        <LoginForm />
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default Loginpage;
